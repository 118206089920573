.new-admin-navbar {
  position: relative;
  width: 100%;
  min-height: 70px;

  a {
    text-decoration: none;
    color: #000;
  }

  &__content {
    width: calc(100% - 20px);
    border-radius: 15px;
    box-shadow: 0 0 13px 7px rgba(0, 0, 0, 0.09);
    position: absolute;
    z-index: 1000;
    top: 10px;
    left: 10px;
    background-color: var(--bs-body-bg);
  }

  &__header {
    padding: 5px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    display: flex;
    align-items: center;

    img {
      max-height: 46px;
    }
  }

  &__title {
    // max-width: 300px;
  }

  &__main-links {
    flex: 1;
  }

  &__burger1,
  &__burger2,
  &__burger3 {
    width: 25px;
    height: 3px;
    background-color: var(--bs-black);
    margin: 6px 0;
    transition: 0.15s;
  }

  .open &__burger1 {
    transform: translate(0px, 9px) rotate(45deg);
  }

  .open &__burger2 {
    opacity: 0;
  }

  .open &__burger3 {
    transform: translate(0px, -9px) rotate(-45deg);
  }

  &__links-block {
    display: flex;
    flex-direction: column;
  }

  &__footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
  }

  &__user {
    text-align: end;
  }
}
