$blue: #2c4fc7;
$green: #448080;
$cyan: rgb(67, 121, 131);
$table-bg-color: rgb(248, 248, 248);

:root {
  --main-color: #2C4FC7;
  --second-color: #F15F79;
  --thrid-color: #c84e89;
}

@import "bootstrap/scss/bootstrap";
@import "~datatables.net-bs5/css/dataTables.bootstrap5.min.css";
@import "air-datepicker/air-datepicker.css";
@import "./navbar";
@import "./calendar.scss";
@import "./table";

body {
  min-height: 100vh;
}

.nounderline {
  text-decoration: none;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  & > .content {
    flex: 1;
  }
}

.page-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  .spinner-border {
    width: 20rem;
    height: 20rem;
  }
}

.modal-xxl {
  --bs-modal-width: 1500px !important;
}


