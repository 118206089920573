.month_table {
  width: 100%;
  display: grid;
  // box-sizing: border-box;
  // table-layout: fixed;
  // border-collapse: collapse;
  // border-spacing: 0;
  font-size: 1em;
  grid-template-columns: repeat(7, 1fr);

  thead,
  tbody,
  tr {
    display: contents;
  }

  td {
    // border-color: #ddd !important;
    border: 1px solid #ddd;
  }

  .fc-day-header {
    background-color: #666;
    color: white;
    padding: 0;
    margin: 0;
    vertical-align: top;
  }

  .fc-past {
    background-color: #c2c7d2 !important;
  }

  td {
    min-height: 100px !important;
  }
}

.opacity-5 {
  opacity: 0.7;
}

.holyday {
  background-color: rgba(218,67,0,0.1) !important;
}