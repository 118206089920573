// .pointer {
//   cursor: pointer;
// }

// .events-calendar {
//   position: relative;
//   td>div {
//     min-height: 100px !important;
//     min-width: 100px;
//   }
// }

// .bg-current-day {
//   background-color: rgb(202 236 255) !important;
// }

// .bg-holiday {
//   background-color: #e54c4c6b !important;
// }

.calendar-event {
  display: block;
  position: relative;
  border-radius: 5px;
  margin-bottom: 3px;
  padding: 0 5px;
  color: #fff;
  text-decoration: none;

  &__info {
    // visibility: hidden;
    opacity: 1;
    display: none;
    position: absolute;
    bottom: 180%;
    left: -76px;
    color: #000;
    // opacity: 1;
    width: 340px;
    &>.card {
      width: 100%;
    }
  }
  &__time {
    font-size: 0.9rem;
  }
  &__pre {
    white-space: pre;
  }

  &:hover &__info {
    display: flex;
  }
  &:hover {
    color: #fff;
    // opacity: 0.2;
  }

  &__add-btn {
    text-align: center;
  }
}

.new-lesson {
  position: relative;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.1s linear;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 30px;
  // background-color: rgba(247, 247, 247, 1);

  &:hover {
    opacity: 0.8;
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: green;
    font-size: 2rem;
  }
}